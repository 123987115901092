<template>
  <div class="big-box">
    <div class="activation" v-show="isActivation">
      <h1>输入邮箱进行激活</h1>
      <input type="text" placeholder="Email" v-model="email" />
      <el-button class="ace-btn" :disabled="canClick" @click="doActivation">{{ content }}</el-button>
      <button class="ace-btn" @click="toPage('login')">返回登录页面</button>
    </div>
    <div class="is-activation" v-show="!isActivation">
      <h1> {{text.first}} </h1>
      <h1> {{text.second}} </h1>
      <h1 class="user">用户: {{currEmail}} </h1>
      <button class="ace-btn" @click="toPage('login')">返回登录页面</button>
      <button class="ace-btn" @click="toPage('activation')">返回激活页面</button>
    </div>
    <!-- 由南宁市兴宁区市场监督管理局的黄雄受理不通过请修改再次提交！退回原因：
    1、《个体工商户登记（备案）申请书》，住所，请按身份证上的地址或是现在实际居住的地址填写，职业状况（待业、个体户、公务员、企业公司职员）请填写；
    2、联络员信息、经营者情况请填写；
    3、《网店铺经营证明》请上传；
    4、经营者以网络经营场所申请设立个体工商户登记的，该经营者《居民身份证》上住所所在地的市场监督管理部门为其登记机关。
      经营者住所与经营者居民身份证载明的住址不一致的，可以持《居住证》，向经常居住地的市场监督管理部门申请办理个体工商户登记；
    5、经营者下载国家市场监管总局的“登记注册身份验证”APP，完成四级实名认证并授权登记注册有效期限，已经完成身份验证的要确保办理业务在10天的有效期内（系统会自动生成）
      方能办理相关业务，如已下载注册，请检查是否在办理业务的有效期内。 -->
  </div>
</template>

<script>
export default {
  data: () => ({
    email: '',
    isActivation: true,
    currEmail: '',
    content: '发送激活邮件',
    canClick: false,
    totalTime: 60,
    text: {
      first: '恭喜您,激活账号成功',
      second: '感谢您的使用！'
    }
  }),
  created() {
    const sendEmailCountDown =  localStorage.getItem('sendEmailCountDown')
    if (sendEmailCountDown === '1') {
      this.countDown()
    }
    if (!this.$route.query.email) {
      // console.log('手动进入');
    } else {
      this.currEmail = this.$route.query.email
      this.currActivation({email: this.currEmail})
    }
  },
  methods: {
    countDown() {
      localStorage.setItem('sendEmailCountDown', '1')
      this.canClick = true
      this.content = this.totalTime + 's后重新发送'
      let clock = window.setInterval(() => {
        this.totalTime--
        this.content = this.totalTime + 's后重新发送'
        if (this.totalTime < 0) {
          window.clearInterval(clock)
          this.content = '重新发送短信'
          this.totalTime = 60
          this.canClick = false
          localStorage.setItem('sendEmailCountDown', 0)
        }
      }, 1000)
    },
    async doActivation() {
      if (this.canClick) return
      const regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!this.email && !regEmail.test(this.email)) {
        this.$message({
          message: "请输入正确的邮箱格式哦~",
          type: "warning",
        });
        return;
      }
      this.countDown()
      
      const params = {
        email: this.email
      }
      await this.$store.dispatch('user/sender', params)
      this.$notify({
        title: this.email,
        message: '已向该账号发送激活邮件,注意查收哦~',
        duration: 0,
        type: 'success'
      });
    },
    sendMsg() {
      if (this.canClick) return
      this.canClick = true
      this.content = this.totalTime + 's后重新发送'
      let clock = window.setInterval(() => {
        this.totalTime--
        this.content = this.totalTime + 's后重新发送'
        if (this.totalTime < 0) {
          window.clearInterval(clock)
          this.content = '重新发送短信'
          this.totalTime = 10
          this.canClick = false
        }
      }, 1000)
    },
    async currActivation(params) {
      const res = await this.$store.dispatch('user/activation', params)
      if (res.code !== 10008) { 
        this.$message('以发送账号激活邮件,请注意查收')
      } else { 
        this.isActivation = false
        this.text = {
          first: '操作或内部出现错误',
          second: '请重新进行操作'
        }
      }
    },
    toPage(page) {
      if (page === 'login') {
        this.$router.push({name: 'Login'})
      } else {
        this.$router.push({name: 'Activation'})
      }
    }
  }
}
</script>

<style scoped>
.big-box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(200deg, #f3e7e9, #e3eeff);
}

.big-box h1 {
  margin-bottom: 70px;
}

.activation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  width: 380px;
  height: 500px;
  background-color: #d3b7d8;
  border-radius: 10px;
  color: #fff;
  text-align: center;
}

.is-activation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  width: 380px;
  height: 500px;
  background-color: #d3b7d8;
  border-radius: 10px;
  color: #fff;
  text-align: center;
}

.user {
  font-size: 20px;
}

input {
  background-color: transparent;
  width: 70%;
  color: #fff;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, .4);
  padding: 10px 0;
  text-indent: 10px;
  margin: 8px 0;
  font-style: 14px;
  letter-spacing: 1px;
  margin-bottom: 70px;
}

input::placeholder {
  color: #fff;
}

input:focus {
  color: #a262ad;
  outline: none;
  border-bottom: 1px solid #a262ad80;
  transition: .5s;
}

input:focus::placeholder {
  opacity: 0;
}

.ace-btn {
  width: 70%;
  margin-top: 35px;
  background-color: #f6f6f6;
  outline: none;
  border-radius: 8px;
  padding: 13px;
  color: #a262ad;
  letter-spacing: 2px;
  border: 2px;
  cursor: pointer;
}

.ace-btn:hover {
  background-color: #a262ad;
  color: #f6f6f6;
  transition: background-color .5s ease;
}
</style>